import { queryMenus } from '@/api/user'
import { message } from 'ant-design-vue'
import { LayoutBlock, LayoutUser, LayoutBase } from '@/layouts'
import { generator } from './handler'
import storage from 'store'
// import mall from './mode'
import common from './common'

// 前端路由表
export const routeComponents = {
  LayoutBlock,
  LayoutUser,
  LayoutBase,

  // 自定义的动态路由 这里有多少个路由在这里注册
  company: () => import('@/views/setting/list'),
  permission: () => import('@/views/setting/permission'),
  role: () => import('@/views/setting/rule_manages'),
  staff: () => import('@/views/setting/staff_manages'),
  message: () => import('@/views/setting/message'),
  overview: () => import('@/views/overview/index'),
  member: () => import('@/views/users/userslist'),
  // 商品中心
  management: () => import('@/views/commodity/management/management'), //产品列表
  // management_gifts: () => import('@/views/commodity/management'),
  management_gifts: () => import('@/views/commodity/management/management_gifts'),//产品赠送
  service: () => import('@/views/commodity/service/service'),//服务列表
  service_gifts: () => import('@/views/commodity/service/service_gifts'),//服务赠送
  plan: () => import('@/views/commodity/plan/plan'),//方案列表
  plan_gifts: () => import('@/views/commodity/plan/plan_gifts'),//赠送方案
  storedvaluecard: () => import('@/views/commodity/storedValueCard/storedvaluecard'),//储值卡列表


  type: () => import('@/views/commodity/type/type_finance'),
  typeFinance: () => import('@/views/commodity/type/type_finance'),
  typeStore: () => import('@/views/commodity/type/type_store'),
  typeMall: () => import('@/views/commodity/type/type_mall'),

  brand: () => import('@/views/commodity/brand'),
  brandsinfos: () => import('@/views/commodity/infos'),
  // 临时合伙人菜单
  partnerMenu: () => import('@/views/partnerMenu/index'), //概况
  partnerMenuLs: () => import('@/views/partnerMenu/index'), //概况
  partnerMenuSetting: () => import('@/views/partnerMenu/setting/index'), //设置
  partnerMenuSettingAdd: () => import('@/views/partnerMenu/setting/add'), //设置
  partnerMenuUser: () => import('@/views/partnerMenu/user/index'), //账户
  partnerMenuEarnings: () => import('@/views/partnerMenu/earnings/index'), //收益
  partnerMenuEarningsLs: () => import('@/views/partnerMenu/earningsLs/index'), //收益ls

  partnerMenuFile: () => import('@/views/partnerMenu/file/index'), //档案


  bonus: () => import('@/views/partnerMenu/earnings/index'), //收益  分红
  commission: () => import('@/views/partnerMenu/earnings/index'), //收益 佣金

  bonusLs: () => import('@/views/partnerMenu/earningsLs/index'), //收益  分红 ls
  commissionLs: () => import('@/views/partnerMenu/earningsLs/index'), //收益 佣金ls

  partnerMenuUserLs: () => import('@/views/partnerMenu/earningsLs/index'),
  partnerMenuFileLs: () => import('@/views/partnerMenu/earningsLs/index'),


  // BI报表
  RegionalSettings: () => import('@/views/reportForms/regionalSettings/regionalSettings'), //区域设置 /reportForms/regionalSettings
  ReportFieldConfiguration: () => import('@/views/reportForms/reportFieldConfiguration/reportFieldConfiguration'),//报表字段配置/reportForms/reportFieldConfiguration
  OperatingStatement: () => import('@/views/reportForms/operatingStatement/operatingStatement'),//营业报表 /reportForms/operatingStatement
  UnconsumptionStatistics: () => import('@/views/reportForms/unconsumptionStatistics/unconsumptionStatistics'), //未消耗统计 /reportForms/unconsumptionStatistics
  CustomerConsumptionStatistics: () => import('@/views/reportForms/customerConsumptionStatistics/customerConsumptionStatistics'),//客户消费统计 /reportForms/customerConsumptionStatistics
  ItemStatistics: () => import('@/views/reportForms/itemStatistics/itemStatistics'), //品项统计 /reportForms/itemStatistics
  ItemSalesRanking: () => import('@/views/reportForms/itemSalesRanking/itemSalesRanking'),//品项销售排名 /reportForms/itemSalesRanking
  ItemSalesStatistics: () => import('@/views/reportForms/itemSalesStatistics/itemSalesStatistics'),//品项销售统计 /reportForms/itemSalesStatistics
  shopUseStatistics: () => import('@/views/reportForms/itemSalesStatistics/itemSalesStatistics'),//品项销售统计 /reportForms/itemSalesStatistics

  // 连锁商城
  malloverview: () => import('@/views/mall/overview.vue'),//概况  /mall/malloverview
  mallshoplist: () => import('@/views/mall/mallshoplist/mallshoplist.vue'),//商城列表  /mall/mallshoplist
  mallgrounding: () => import('@/views/mall/mallshoplist/mallshoplist.vue'),//商城列表 已上架  /mall/mallshoplist/mallgrounding
  malloffshelf: () => import('@/views/mall/mallshoplist/malloffshelf.vue'),//商城列表 已下架  /mall/mallshoplist/mallgrounding
  Mallorder: () => import('@/views/mall/mallorder/saleslip.vue'),//商城订单	
  Saleslip: () => import('@/views/mall/mallorder/saleslip.vue'),//销售单列表	 	
  Selfpickupgoods: () => import('@/views/mall/mallorder/selfpickupgoods.vue'),//自提商品
  Verificationservice: () => import('@/views/mall/mallorder/verificationservice.vue'),//核销服务
  AfterSales: () => import('@/views/mall/mallorder/afterSales.vue'),//售后
  Mallusers: () => import('@/views/mall/mallusers/malluserslist.vue'),// 商城用户
  Malluserslist: () => import('@/views/mall/mallusers/malluserslist.vue'),// 用户列表
  Userassets: () => import('@/views/mall/mallusers/userassets.vue'),// 用户资产
  Mallsetting: () => import('@/views/mall/mallsetting/expresssettings.vue'),// 设置   
  Expresssettings: () => import('@/views/mall/mallsetting/expresssettings.vue'),// 快递设置
  PaymentConfiguration: () => import('@/views/mall/mallsetting/paymentConfiguration.vue'),// 支付配置
  Operationmanagement: () => import('@/views/mall/malloperation/applet'),//运营管理
  Applet: () => import('@/views/mall/malloperation/applet'),//小程序配置
  //  \views\mall\operationmanagement
  universalPayConfig: () => import('@/views/user/login'),  // 通用支付配置
  shopPayConfig: () => import('@/views/user/login'),  // 门店支付配置

  // 内容管理
  CMS_Channel: () => import('@/views/user/login'),  // 频道管理
  CMS_Imagetext: () => import('@/views/user/login'),  // 图文管理
  CMS_Sourcematerial: () => import('@/views/user/login'),  // 素材管理	
  // 售后中心
  displaceOrder: () => import('@/views/user/login'), // 置换工单
  refundOrder: () => import('@/views/user/login'), // 售后工单
}
// 动态生成菜单
export const generatorDynamicRoutes = () => {

  // return queryMenus().then(res => { 

  let obj = {
    id: 1,
    pid: 0,
    title: "/",
    icon: "meiye-routeicon-comment",
    path: "/",
    component: "LayoutBase",
    params: "",
    children: storage.get('menu')
  }
  let data = [obj]
  const invalidMenu = !data || !data.length || data[0].path !== '/'
  // 兼容后端接口数据返回不规范的情况
  if (invalidMenu) {
    message.error('菜单树接口返回的数据格式不规范')
    data = [{
      path: '/',
      component: 'LayoutBase',
      children: []
    }]
  }

  // const invalidMenu = !data || !data.length || data[0].path !== '/'
  // 兼容后端接口数据返回不规范的情况
  // if (invalidMenu) {
  //   message.error('菜单树接口返回的数据格式不规范')
  //   data = [{
  //     path: '/',
  //     component: 'LayoutBase',
  //     children: []
  //   }]
  // }
  // 将通用路由添加到根路由下面
  const rootRoute = data.find(item => item.path === '/') || {}
  if (rootRoute.children) {
    // rootRoute.children = rootRoute.children.concat(template, common)

    // rootRoute.children = rootRoute.children.concat(mall)
    rootRoute.children = rootRoute.children.concat(common)
  }

  const menus = generator(data, routeComponents)
  return menus
}
